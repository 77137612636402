import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { TokenManagerService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UsuarioElevadoService } from 'src/app/core/security/usuario-elevado.service';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { ButacasService } from 'src/app/modules/auditorio/services/butacas.service';
import { DevolucionService } from 'src/app/modules/gestionar-venta/services/devolucion.service';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { Carrito } from 'src/app/modules/venta';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { ProcedenciaService } from 'src/app/modules/venta/services/procedencia.service';
import { StepperNavigationService } from 'src/app/modules/venta/services/stepper-navigation.service';
import { VentaService } from 'src/app/modules/venta/services/venta.service';
import { EncriptaService } from 'src/app/shared/services/encripta.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';

@Component({
  selector: 'ticketing-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('formsub') form: ElementRef;
  @ViewChild('formadmin') formAdmin: ElementRef;
  urlAgenda: string = this.config.getConfig('ADMIN_AGENDA_URL');
  urladmin: string = this.config.getConfig('ADMIN_INDEX_URL');
  secciones = [];
  ventanaActualDePago: boolean;
  ventanaLocalizador: boolean;
  ventanaGestionar: boolean;
  ventanaHoras: boolean;

  desmarcarSub: Subscription;
  private alive = true;
  sub;
  window = window as any;
  constructor(
    private router: Router,
    private sidebarNavigation: SidebarNavigationService,
    private stepperNavigation: StepperNavigationService,
    private pagoService: PagoService,
    private ventaService: VentaService,
    public localizadorService: LocalizadorService,
    private motivosService: MotivosService,
    private permisoService: PermisosService,
    private user: UserManagerService,
    private usuarioElevadoService: UsuarioElevadoService,
    private formControlService: FormControlService,
    private butacaService: ButacasService,
    private config: AppConfiguration,
    private http: HttpClient,
    private toast: ToastrService,
    private devolucionService: DevolucionService,
    private procedenciaService: ProcedenciaService,
    private tokenManager: TokenManagerService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ventanaActualDePago =
          val.url.split('/')[2] === 'pago' ? true : false;
      }
      if (val instanceof NavigationEnd) {
        this.ventanaHoras = val.url.split('/')[2] === 'horarios' ? true : false;
      }
      if (val instanceof NavigationEnd) {
        this.ventanaLocalizador =
          val.url.split('/')[1] === 'localizador' ? true : false;
      }
      if (val instanceof NavigationEnd) {
        this.ventanaGestionar =
          val.url.split('/')[1] === 'gestionar-venta' && val.url.split('/')[2]
            ? true
            : false;
      }
    });
  }
  ngOnInit() {
    this.sidebarNavigation.getNavigation().subscribe((secciones) => {
      const Padre = secciones.find((x) => x.ModuloIdPadre == '0');
      this.secciones = secciones.filter((y) => y.ModuloIdPadre == Padre.PkId);
    });
    this.ventaService.carrito.subscribe((carrito) => {
      if (carrito.entradas.length === 0) {
        this.sidebarNavigation.unblockSecciones();
      } else {
        this.sidebarNavigation.blockSecciones();
      }
    });
  }

  navegar(url) {
    const seccion = this.sidebarNavigation.getSeccionByUrl(url);
    url = url ? url.toLowerCase() : '';
    this.sidebarNavigation.setSeccionSeleccionada(seccion);
    if (url === 'agenda') {
      //this.router.navigate(['agenda-login']);
      this.abriragenda();
      return;
    }
    if (url === 'adminonline') {
      this.abriradminOnline();
      return;
    }
    if (url === 'abrircajon') {
      this.abrirCajon();
      return;
    }
    //const seccion = this.sidebarNavigation.getSeccionByUrl(url);

    if (seccion.disabled) {
      return;
    }
    if (url === 'venta') {
      this.stepperNavigation.resetearPasos();
      if (this.router.url.includes('venta')) {
        this.stepperNavigation.desbloquearNavegacionPorPago();
        this.stepperNavigation.continuarVenta.next(false);
      }
      this.router.navigate(['']);
    } else if (url === 'localizador') {
      this.localizadorService.noSearch = true;
      this.router.navigate([`/${url}`]);
    } else if (url === 'turno') {
      if (
        !this.permisoService.puedeAccionar(
          this.user.currentUserValue,
          'TurnoConsultar',
          {
            toast: true,
            elevarUsuario: true,
          }
        )
      ) {
        this.usuarioElevadoService.haSidoElevado$.subscribe((elevado) => {
          if (elevado && this.router.url !== '/venta/pago') {
            this.router.navigate([`/${url}`]);
          }
        });
      } else {
        this.router.navigate([`/${url}`]);
      }
    } else {
      this.router.navigate([`/${url}`]);
    }
  }

  cancelarVenta(): void {
    if (this.ventanaActualDePago || this.ventanaHoras) {
      // this.ventaService.setFechaSeleccionadaValue(
      //   this.ventaService.carritoValue.entradas[0].entrada.Fecha === '' ? moment() : moment(this.ventaService.carritoValue.entradas[0].entrada.Fecha )
      // );
      //Comprobamos marcarExterno
      let marcarExterno = this.config.getConfig('MARCAR_EXTERNO');
      if (!marcarExterno || marcarExterno == '') marcarExterno = '0';
      if ((marcarExterno = '1')) {
        if (
          this.ventaService.idMarcadoExterno != null &&
          this.ventaService.idMarcadoExterno.trim() != ''
        ) {
          //Borramos en API
          this.desmarcarSub = this.cancelarMarcadoExterno().subscribe(
            (res: any) => {
              //Comprobamos la devolucion
              if (res.DatosResult != null) {
                //TODO OK
                // ;
              } else {
                this.toast.info(
                  'Ha sido imposible desmarcar aforos en Escuela. Revise la anulacion en Escuela',
                  'Info',
                  {
                    toastComponent: InfoToast,
                    timeOut: 7500,
                  }
                );
              }
            }
          );
        }
        this.ventaService.idMarcadoExterno = null;
      }
      //
      if(this.ventaService.fechaSeleccionadaValue === '') {
        this.ventaService.clearCarrito();
        this.ventaService.setFechaSeleccionadaValue(moment());
      }
      this.sidebarNavigation.unblockVentaAuditorio();
      this.ventaService.carritoModificado = false;
      this.ventaService.setHayDescuento(false); //Yaribel 20210730  cliente Mapfre VSTS 8370
      this.ventaService.currentRecinto = undefined;
      this.stepperNavigation.desbloquearNavegacionPorPago();
      this.pagoService.setPagoTotal(undefined);
      this.pagoService.setCambio(undefined);
      this.pagoService.setDiferencia(undefined);
      this.devolucionService.setPagoTotal(undefined);
      this.devolucionService.setCambio(undefined);
      this.stepperNavigation.continuarVenta.next(false);
      this.stepperNavigation.resetearPasos();
      this.ventaService.carritoSubject
        .pipe(first())
        .subscribe((res1: Carrito) => {
          if (res1.carrito) {
            res1.setCarritoModificacionCarritoValue(res1.carrito);
            this.ventaService.carritoSubject.next(res1);
          }
        });
      if (this.config.getConfig('COMPRAINICIO')) {
        this.ventanaActualDePago = false;
        this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
      } else {
        this.router.navigate(['']);
      }
      return;
    }
    if (this.ventanaLocalizador) {
      this.localizadorService.noSearch = true;
      this.router.navigate(['/localizador']);
      return;
    }
    if (this.ventanaGestionar) {
      this.router.navigate(['/gestionar-venta']);
      return;
    }
    this.butacaService.httpPostDesamarcarButacasBySesionId().subscribe();
    this.butacaService.removeAllButaca();
    this.ventaService.clearCarrito();
    this.ventaService.eliminaReservaAforoCompleto().subscribe();
    this.ventaService.currentRecinto = undefined;
    this.motivosService.setMotivosSeleccionados([]);
    this.ventaService.setFechaSeleccionadaValue();
    this.ventaService.setClienteSeleccionadoValue('');
    this.procedenciaService.setProcedenciasValue(undefined);
    this.ventaService.currentCliente='0'
    this.formControlService.unfocus();
    this.stepperNavigation.resetearPasos();
    this.secciones.forEach((seccion) => {
      seccion.disabled = false;
    });
    this.stepperNavigation.continuarVenta.next(false);
    if (this.config.getConfig('COMPRAINICIO')) {
      this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
    } else {
      this.router.navigate(['']);
    }
  }

  abriragenda(): void {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const pass = JSON.parse(localStorage.getItem('currentPwd'));
    const path = JSON.parse(localStorage.getItem('currentPath')) || undefined;
    const currentToken = this.tokenManager.currentTokenValue;
    const cadena =
      '{"nombre": "' +
      user +
      '", "contrasena": "' +
      pass +
      '", "ConexionIacpos": "' +
      conexionId +
      (path ? '", "tpvPath": "' + path : '') +
      (currentToken ? '", "tokenOrigen": "' + currentToken.token : '') +
      '"}';
    let argsStr = EncriptaService.encripta(cadena);
    let url = this.config.getConfig('ADMIN_AGENDA_URL');
    if (!url || url == '')
      url = 'http://admin.madridsnowzone.com/admin/Agenda.aspx';
    url = url + '?ARGS=' + argsStr + '&CallAgenda';
    //llamar a Kore para que abra la pestaña agenda si no la tiene (elimino la opcion, porque Marigel va a contolarlo en el open del PopUp)
    if (
      this.window.external &&
      typeof this.window.external.abrirVentana === 'function'
    ) {
      // this.window.external.abrirVentana(
      //   url,
      //   'Agenda'
      // );
      this.router.navigate([]).then((result) => {
        window.open(url, 'Agenda');
      });
    } else {
      this.router.navigate([]).then((result) => {
        // window.open(
        //   url,
        //   '_blank'
        // );
        this.form.nativeElement.hdargumentos.value = argsStr;
        window.open('', 'Agenda');
        this.form.nativeElement.submit();
      });
    }
    this.router.navigate(['']);
  }

  abriradminOnline(): void {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const pass = JSON.parse(localStorage.getItem('currentPwd'));
    const path = JSON.parse(localStorage.getItem('currentPath')) || undefined;
    const currentToken = this.tokenManager.currentTokenValue;
    const cadena =
      '{"nombre": "' +
      user +
      '", "contrasena": "' +
      pass +
      '", "ConexionIacpos": "' +
      conexionId +
      (path ? '", "tpvPath": "' + path : '') +
      (currentToken ? '", "tokenOrigen": "' + currentToken.token : '') +
      '"}';
    let argsStr = EncriptaService.encripta(cadena);
    let url = this.config.getConfig('ADMIN_INDEX_URL');
    if (!url || url == '')
      url =
        'http://admin.madridsnowzone.com/admin/MenuOperacionesEspeciales.aspx';
    url = url + '?ARGS=' + argsStr;
    //llamar a Kore para que abra la pestaña agenda si no la tiene
    if (
      this.window.external &&
      typeof this.window.external.abrirVentana === 'function'
    ) {
      // this.window.external.abrirVentana(
      //   url,
      //   'Administracion'
      // );
      this.router.navigate([]).then((result) => {
        window.open(url, 'Admin');
      });
    } else {
      this.router.navigate([]).then((result) => {
        // window.open(
        //   url,
        //   '_blank'
        // );
        this.formAdmin.nativeElement.Action = url;
        this.formAdmin.nativeElement.hdargumentos.value = argsStr;
        this.window.open('', 'Admin');
        this.formAdmin.nativeElement.submit();
      });
    }
    this.router.navigate(['']);
  }

  abrirCajon(): void {
    //llamar a Kore para que abra el cajon
    if (
      this.window.external &&
      typeof this.window.external.abrirCajon === 'function'
    ) {
      this.window.external.abrirCajon();
    } else {
    }
  }

  cancelarMarcadoExterno() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      idMarcadoExterno: this.ventaService.idMarcadoExterno,
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/VentaTaquilla/DesMarcarVentaExterna`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
